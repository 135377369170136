.page .title {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid white;
  padding-top: 3rem !important;
  padding-bottom: 1.5rem !important;
}

.page .content p {
  padding-top: 3rem !important;
  line-height: 28px;
  text-align: justify;
}

.doc-page .content p {
  text-align: center;
  padding-bottom: 06px;
}

.hero-section,
.cinema-hero-section {
  width: 100%;
  position: relative;
}

.hero-section .hero-img-section,
.cinema-hero-section .hero-img-section {
  width: 100%;
  position: relative;
  /*height: 750px;*/
  height: auto;
}

.hero-section .hero-img-section .hero-img-1,
.cinema-hero-section .hero-img-section .hero-img-1 {
  width: 100%;
  height: 450px;
  max-height: 1000px;
  object-fit: cover;
  object-position: center;
}

.hero-section .hero-text,
.cinema-hero-section .hero-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background: linear-gradient(359.67deg,
      #17161d 0.29%,
      rgba(23, 22, 29, 0) 99.71%);
}

.copyright-text .img-one {
  width: 120px;
}

.copyright-text .img-two {
  width: 100px;
}

.cinema-hero-section .title {
  font-size: 21px;
  margin-bottom: 24px;
  font-weight: bold;
}

.cinema-hero-section .hero-text>div {
  position: relative;
  top: 30%;
}

.cinema-hero-section .msg {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 88px;
  line-height: 22px;
}

.home-content .title {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 20px;
}

.home-content .msg {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: justify;
  line-height: 24px;
}

.home-content iframe {
  width: 100%;
  height: 100%;
}

.home-content:not(:last-of-type) {
  margin-bottom: 50px;
}

.main-menu .brand-logo {
  /* max-width: 175px; */
  height: auto;
}

.main-menu .brand-logo {
  width: 240px;
}

.dots-1 .slick-dots {
  bottom: 08%;
}

.main-menu {
  position: absolute;
  height: 75px;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  /* background: #17161D; */
}

.main-menu {
  transition: background-color 0.25s;
}

@media(min-width: 1200px) {

  .copyright-text .img-one {
    width: 150px;
  }

  

  .copyright-text .img-two {
    width: 120px;
  }

  .dots-1 .slick-dots {
    bottom: 24%;
  }

  .hero-section .hero-img-section .hero-img-1,
  .cinema-hero-section .hero-img-section .hero-img-1 {
    height: auto;
  }

  .cinema-hero-section .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .cinema-hero-section .msg {
    line-height: 28px;
  }

  .home-content iframe {
    width: 640px;
    height: 360px;
    margin-bottom: 60px;
  }

  .home-content:not(:last-of-type) {
    margin-bottom: 204px;
  }

  .home-content:first-of-type {
    margin-top: 114px;
  }

  .home-content .title {
    font-size: 44px;
  }

  .doc-page .content {
    display: grid;
    grid-template-columns: 40% 40%;
    grid-column-gap: 20%;
    align-items: center;
  }

  .doc-page .content p {
    text-align: center;
    padding-bottom: 40px;
  }
}

.wg-drop.country-selector {
  background-color: #e70d00 !important;
}

.wg-drop.country-selector a {
  color: white !important;
}



.country-selector {
  width: 140px;
}

.wg-drop.country-selector:not(.closed) ul {
  background-color: #e70d00 !important;
}